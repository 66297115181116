import React from 'react'
import Image from "gatsby-image";
import styled from 'styled-components'
import {graphql, Link} from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import Seo from "../components/Seo/seo";
import Layout from "../components/Layout/layout";
import PageBody from "../components/PageBody/pageBody";
import GlobalTheme from "../components/Layout/global-theme";
import media from 'styled-media-query'

const TeamMemberBody = styled(({className, member, hideName}) => (
  <section className={className}>
    {hideName || <h3 className={`${className}__name`}>{member.name}</h3> }
    <Image className={`${className}__portrait`} fluid={member.image.childImageSharp.fluid}/>
    <p className={`${className}__introduction`}>{member.introduction}</p>
  </section>
))`
  position: relative;
  min-height: 256px;
    
  ${media.lessThan('medium')`
    margin-top: 1.78rem;
  `}

  &__name {
    margin-top: 0;
    margin-left: 224px;
    
    ${media.lessThan('medium')`
      margin-left: 0;
    `}
  }
  
  &__portrait {
    width: 200px;
    position: absolute !important;
    top: 0;
    left: 0;
    
    ${media.lessThan('medium')`
      position: relative !important;
      width: 100%;
      max-width: 480px;
      margin: 0 auto 24px;
    `}
  }
  
  &__introduction {
    margin-left: 224px;
    
    ${media.lessThan('medium')`
      margin-left: 0;
    `}
  }
`

const CloseButton = styled(({className, closeTo}) => (
  <Link className={`${className}__close-button`} to={closeTo}>×</Link>
))`
  &__close-button {
    border: thin solid ${props => props.theme.colors.primary};
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin: 0 0 0 auto;
  }
`

const TeamMember = styled(({className, data}) => {
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => modal
        ? (
          <div>
            <GlobalTheme>
              <CloseButton closeTo={closeTo}/>
              <TeamMemberBody member={data.membersYaml}/>
            </GlobalTheme>
          </div>
        )
        : (
          <>
            <Seo slug={data.membersYaml.path}/>
            <Layout>
              <PageBody h1={data.membersYaml.name}>
                <TeamMemberBody member={data.membersYaml} hideName={true}/>
              </PageBody>
            </Layout>
          </>
        )}
    </ModalRoutingContext.Consumer>
  )
})`
`

export const query = graphql`
  query TeamMemberQuery($path: String) {
    membersYaml(path: {eq: $path}) {
      name
      introduction
      image {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

// export default TeamMember
export default () => {
    if (typeof window !== 'undefined') {
        window.location = '/';
    }

    return null;
}
