import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

const PageBodyCore = ({className, h1, children}) => (
  <article className={className}>
    <h1>{h1}</h1>
    {children}
  </article>
)

const PageBody = styled(PageBodyCore)`
  max-width: calc(1200px - 48px);
  margin: 0 auto;
  padding: 0 24px;
`

export default PageBody